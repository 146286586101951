import React, { useId } from 'react';
import { t } from "i18next";
import { archiveMail } from '../../services/api/dataServiceOutlook';
import {  useNavigate } from 'react-router-dom';
import { ToastIntent, Toaster, makeStyles, useToastController } from '@fluentui/react-components';
import { getToastMessageComponent } from '../../common/components/ToastMessage';
import { getEnvironment360URL } from '../../services/configs/appConfig';
import SpinnerComponent from '../../common/components/controls/spinner';

const useStyles = makeStyles({
    spinnerStyles:
     {
        paddingTop: "30vh"
     },
  });
const ArchiveMail: React.FC = () => {
    const loadingText = t("Archiving files to 360...");
    const [showSpinner, setShowSpinner] = React.useState(false);
    const toasterId = useId();
    const { dispatchToast ,dismissToast } = useToastController(toasterId);

    const navigate = useNavigate();
    const showToast = (message:string ,toastType:ToastIntent)=>{
        dispatchToast(
            getToastMessageComponent(message,archiveTheMail),
            { timeout:-1, intent: toastType }
          );
    };

    const openOfficeDialogToArchive = (resData: string)=>{
        Office.context.ui.displayDialogAsync(window.location.origin + "/dialogrediect.html?docno="
            + encodeURIComponent(resData) + "&public360Url=" + encodeURIComponent(getEnvironment360URL()), {height: 70, width: 52, promptBeforeOpen: false},
            function (asyncResult) {
            if (asyncResult.status === Office.AsyncResultStatus.Failed) {
                setShowSpinner(false);
                showToast(t("Failed to open dialog: ") + asyncResult.error.message ,"error");
            } else {
                const dialog = asyncResult.value;

                // Add event handler for when the dialog is closed
                dialog.addEventHandler(Office.EventType.DialogEventReceived,  ()=> {
                    onDialogClosed(resData);
                });
            }
            }
        );
    };

   const archiveTheMail  = ()=> {
    dismissToast(toasterId);
    setShowSpinner(true);
    let checkWindowClosed: NodeJS.Timeout;
    try {
        archiveMail((resData: string | null) => {
            if (resData === null) {
                showToast(t("Recno not generated"),"error");
                setShowSpinner(false);
            } else {
                openOfficeDialogToArchive(resData);
        }
    }, (err: string) => {
         setShowSpinner(false);
         showToast(err,"error");
        });
    }
    catch (exception) {
        setShowSpinner(false);
        showToast(exception as string,"error");
    }

    return () => clearInterval(checkWindowClosed);
   };

    React.useEffect(() => {
        archiveTheMail();
    },[]);

    function onDialogClosed(docRecno: string) {
        setShowSpinner(false);
        navigate(`/archivehome?docRecno=${encodeURIComponent(docRecno)}`);
    }

    return (
        <>
            <Toaster toasterId={toasterId} />
            <SpinnerComponent showSpinner={showSpinner} size='medium' label={loadingText} labelPosition='after' spinnerStyle={useStyles().spinnerStyles}/>
        </>
    );
};

export default ArchiveMail;
