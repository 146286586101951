import React, { useState,useMemo, ReactNode } from 'react';
import { teamsLightTheme, teamsDarkTheme, Theme } from '@fluentui/react-components';
import { ThemeContext,ThemeContextType} from './ThemeContext';

export const ThemeProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [theme, setTheme] = useState<Theme>(teamsLightTheme);

  const toggleTheme = () => {
    setTheme((prevTheme) => (prevTheme === teamsLightTheme ? teamsDarkTheme : teamsLightTheme));
  };

  const providerValue: ThemeContextType = useMemo(() => ({
        theme: theme,
        toggleTheme: toggleTheme,
    }), [theme]);

  return (
    <ThemeContext.Provider value={providerValue}>
      {children}
    </ThemeContext.Provider>
  );
};

