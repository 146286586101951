import { Route, Routes, Navigate  } from 'react-router-dom';
import Home from '../common/components/home/home';
import ArchiveMail from '../Outlook/archiveMail/archiveMail';
import ArchiveFile from '../Office/archiveFile/archiveFile';

export const routes = (
    <Routes >
        <Route path='/' element={<Home />} />
        <Route path='/archivehome' element={<Home />} />
        <Route path='/archiveMail' element={<ArchiveMail />}/>
        <Route path='/archiveFile' element={<ArchiveFile />}/>
        <Route path="*" element={<Navigate to="/" />} />
    </Routes >
);