import React from 'react';
import { IDocumentDetails, MAIL_STATUS } from '../@types/models';
import SpinnerComponent from '../common/components/controls/spinner';
import {findAndGetMailInfo} from '../services/api/dataServiceOutlook';
import { makeStyles,
    useId,
    Toaster,
    useToastController,
    ToastIntent} from '@fluentui/react-components';

import { t } from "i18next";
import { getDocumentDetailComp, getArchiveHome } from '../common/components/home/HomeComponents';
import { getToastMessageComponent } from '../common/components/ToastMessage';
import { getEnvironment360URL } from '../services/configs/appConfig';

const useStyles = makeStyles({
    containerbody:
     {
        width: "100%",
        minHeight: "inherit",
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column'
     },

  });

export const OutlookHome: React.FC = () => {
    const loadingText = t("Loading...");
    const [showSpinner, setShowSpinner] = React.useState(false);
    const [errorMsg, setErrorMsg] = React.useState("");
    const [loadingStatus, setLoadingStatus] = React.useState(MAIL_STATUS.NOT_EXISTS);
    const [documentInfo, setDocumentInfo] = React.useState<IDocumentDetails>();
    const styles = useStyles();
    const toasterId = useId("toastIdHome");
    const { dispatchToast ,dismissToast } = useToastController(toasterId);



    const showToast = (message:string ,toastType:ToastIntent)=>{
        setTimeout(() => {
            dispatchToast(
                getToastMessageComponent(message, () => checkCaseExists(null)),
                {intent: toastType }
            );
        }, 100); // Small delay of 100ms
    };

    const processMail = ()=>{
        checkCaseExists(null);
    };

    const checkCaseExists = React.useCallback((recno : string|null  =null) => {
        dismissToast(toasterId);
        setShowSpinner(true);
        setLoadingStatus(MAIL_STATUS.LOADING);
        try {
            findAndGetMailInfo((resData: IDocumentDetails | null) => {
                if (resData === null) {
                    setLoadingStatus(MAIL_STATUS.NOT_EXISTS);
                    setShowSpinner(false);
                } else {
                    setDocumentInfo(resData);
                    setLoadingStatus(MAIL_STATUS.EXISTS);
                    setShowSpinner(false);
                }
            }, (err: string) => {
                setShowSpinner(false);
                setErrorMsg(err);
                setLoadingStatus(MAIL_STATUS.ERROR);
            }, recno);
        } catch (exception) {
            setShowSpinner(false);
            if (typeof exception === 'string')
                setErrorMsg(exception);
            else if (exception instanceof Error)
                setErrorMsg(exception.message);
            setLoadingStatus(MAIL_STATUS.ERROR);
        }
    }, [dismissToast, toasterId]);

     React.useEffect(() => {
        const docRecno = new URLSearchParams(window.location.search).get("docRecno");
        if(getEnvironment360URL()){
            checkCaseExists(docRecno);
        }else{
            setErrorMsg(t("The 360 url not set correctly"));
            setLoadingStatus(MAIL_STATUS.ERROR);
        }

    },[checkCaseExists]);

    React.useEffect(() => {

        Office.context.mailbox.addHandlerAsync(Office.EventType.ItemChanged,processMail );
        return () => {
            Office.context.mailbox.removeHandlerAsync(Office.EventType.ItemChanged);
        };
    },[]);

    let uiToDisplay ;
    if (loadingStatus === MAIL_STATUS.ERROR) {
        showToast(errorMsg, "error");
        uiToDisplay = getArchiveHome();
        // Could use a mew message/component to show after the firt try failed. Showing the home page for now instead of blank page
    } else if (loadingStatus === MAIL_STATUS.EXISTS && documentInfo) {
        uiToDisplay = getDocumentDetailComp(documentInfo);
    } else if (loadingStatus === MAIL_STATUS.NOT_EXISTS) {
        uiToDisplay = getArchiveHome();
    }
    return (
        <div className={styles.containerbody}>
            {uiToDisplay}
            <Toaster toasterId={toasterId} />
            <SpinnerComponent showSpinner={showSpinner} size='medium' label={loadingText} labelPosition='after' />
        </div>
    );
};

export default OutlookHome;
