import React from 'react';
import { createRoot } from 'react-dom/client';
import AppWrapper from './App';
import reportWebVitals from './reportWebVitals';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';

function launch() {
    const el = document.getElementById('root');
    if (el) {
        const root = createRoot(el);
        root.render(
            <React.StrictMode>
                <I18nextProvider i18n={i18n}>
                    <AppWrapper />
                </I18nextProvider>
            </React.StrictMode>
        );
    }
}


Office.onReady(() => {
    launch();
 }).catch((e)=>{
window.console.log('exception:', e);
});

reportWebVitals();

