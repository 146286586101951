
import OfficeHome from "../../../Office/OfficeHome";
import OutlookHome from "../../../Outlook/OutlookHome";
import { isOutlookApp } from "../../../utils/Helper";

export const Home = () => {

if(isOutlookApp()){
    return (
        <OutlookHome/>
    );
}else{
    return (
        <OfficeHome/>
    );
}
};
export default Home;