import React from 'react';
import { IDocumentDetails, FILE_STATUS } from '../@types/models';
import SpinnerComponent from '../common/components/controls/spinner';
import {
    makeStyles,
    useId,
    Toaster,
    useToastController,
    ToastIntent
} from '@fluentui/react-components';

import { t } from "i18next";
import { getArchiveHome, getDocumentDetailComp } from '../common/components/home/HomeComponents';
import { getToastMessageComponent } from '../common/components/ToastMessage';
import { getEnvironment360URL } from '../services/configs/appConfig';
import { findAndGetFileInfo, getFileDetailsFromFileUid } from '../services/api/dataServiceOffice';

const useStyles = makeStyles({
    containerbody:
    {
        width: "100%",
        minHeight: "inherit",
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column'
    },

});

export const OfficeHome: React.FC = () => {
    const loadingText = t("Loading...");
    const [showSpinner, setShowSpinner] = React.useState(false);
    const [errorMsg, setErrorMsg] = React.useState("");
    const [loadingStatus, setLoadingStatus] = React.useState<FILE_STATUS>(FILE_STATUS.NOT_EXISTS);
    const [documentInfo, setDocumentInfo] = React.useState<IDocumentDetails>();
    const styles = useStyles();
    const toasterId = useId("toastIdHome");
    const { dispatchToast, dismissToast } = useToastController(toasterId);


    const showToast = (message: string, toastType: ToastIntent) => {
        setTimeout(() => {
            dispatchToast(
                getToastMessageComponent(message, () => checkCaseExists()),
                { intent: toastType }
            );
        }, 100); // Small delay of 100ms
    };

    const checkFileUsingFileUID = React.useCallback((fileUid: string) => {
        // Get the file details from the fileUid

        getFileDetailsFromFileUid(fileUid).then((res) => {
            setDocumentInfo(res);
            setLoadingStatus(FILE_STATUS.EXISTS);
        }).catch(() => { setLoadingStatus(FILE_STATUS.NOT_EXISTS); });




    }, []);
    const checkCaseExists = React.useCallback(() => {
        dismissToast(toasterId);
        setLoadingStatus(FILE_STATUS.NOT_EXISTS);
        setShowSpinner(true);
        const docRecno = new URLSearchParams(window.location.search).get("docRecno");
        try {
            if (docRecno) {
                findAndGetFileInfo(docRecno, (resData: IDocumentDetails) => {
                    setShowSpinner(false);
                    if (resData === null) {
                        setLoadingStatus(FILE_STATUS.NOT_EXISTS);
                        setShowSpinner(false);
                    } else {
                        setDocumentInfo(resData);
                        setLoadingStatus(FILE_STATUS.EXISTS);
                        setShowSpinner(false);
                    }
                }, (err: string) => {
                    setShowSpinner(false);
                    showToast(err, "error");
                });
            } else {
                setLoadingStatus(FILE_STATUS.NOT_EXISTS);
                setShowSpinner(false);
            }

        } catch (exception: unknown) {
            setShowSpinner(false);
            setShowSpinner(false);
            showToast(exception as string, "error");
        }


    }, [dismissToast, showToast, toasterId]);

    React.useEffect(() => {

        if (getEnvironment360URL()) {
            //checkCaseExists();
            checkFileUsingFileUID("8781BBB2-DE72-4E61-8108-8E0057B00CD7");

        } else {
            setErrorMsg(t("The 360 url not set correctly"));
            setLoadingStatus(FILE_STATUS.ERROR);
        }

    }, [checkFileUsingFileUID]);


    let uiToDisplay;
    if (loadingStatus === FILE_STATUS.ERROR) {
        showToast(errorMsg, "error");
        uiToDisplay = getArchiveHome();
        // Could use a mew message/component to show after the firt try failed. Showing the home page for now instead of blank page
    } else if (loadingStatus === FILE_STATUS.EXISTS && documentInfo) {
        uiToDisplay = getDocumentDetailComp(documentInfo);
    } else if (loadingStatus === FILE_STATUS.NOT_EXISTS) {
        uiToDisplay = getArchiveHome();
    }
    return (
        <div className={styles.containerbody}>
            {uiToDisplay}
            <Toaster toasterId={toasterId} />
            <SpinnerComponent showSpinner={showSpinner} size='medium' label={loadingText} labelPosition='after' />
        </div>
    );
};
export default OfficeHome;