
import fetchClient from "./fetchClient";
import {getEnvironment360BizURL,getFetchApiOptions, ApiConfig} from '../configs/appConfig';

export const getAccessToken = async () =>{

    const accessToken = await OfficeRuntime.auth.getAccessToken({allowSignInPrompt: true,
        allowConsentPrompt: true,
        forMSGraphAccess: true,
        })
        .catch(function (err ) {
            throw new Error("code: "+ err?.code +" and message " + err?.message);
        });

        return accessToken;
};

export const getConfigs = async () => {

    try {
        const response = await fetchClient(getEnvironment360BizURL() +
        ApiConfig.getConfig, {
        body: {},
        headers: getHeaders(),
        method: "POST",
        ...getFetchApiOptions
      });

        return response;
      } catch (err) {
        return null;
      }
};

export const getHeaders = async (isStream?: boolean)=>{
    const token = await getAccessToken();
    return {
        "Accept": "application/json",
        "Accept-Language": document.documentElement.lang,
        "Authorization": `Bearer ${token}`,
        "Content-Type": isStream? "application/octet-stream" : "application/json"
    };

};
