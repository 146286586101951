export enum MAIL_STATUS {
    EXISTS = "Exists",
    NOT_EXISTS = "NotExists",
    LOADING = "Loading",
    ERROR = "Error"
}
export enum FILE_STATUS {
    EXISTS = "Exists",
    NOT_EXISTS = "NotExists",
    LOADING = "Loading",
    ERROR = "Error"
}
export interface IMailInfo {
    restUrl: string;
    serviceToken: string;
    itemId: string;
    docRecno: string | null;
}

export interface IDocumentDetails {
    AccessCodeDesc: string;
    CaseDescription: string;
    ArchiveCode: string;
    CaseNumber: string;
    CategoryCode: string;
    DocumentCategory?: string;
    DocumentDetailsLink: string;
    DocumentTitle: string;
    DocumentNumber: string;
    OrgUnitName: string;
    Paragraph: string;
    ResponsibleName: string;
    Documentnumber?: string;
}
export interface IConfigInfo {
    VersionOf360ForHelp : string;
}