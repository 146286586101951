import { Toast, ToastTitle, ToastTrigger, Button } from "@fluentui/react-components"
import { t } from "i18next"

export const getToastMessageComponent = (message:string , onClick:()=>void)=>{

   return(
        <Toast>
            <ToastTitle
                action={
                    <ToastTrigger>
                        <Button onClick={onClick}>{t("Try again")}</Button>
                    </ToastTrigger>
                }
                >
                    {message}
            </ToastTitle>
        </Toast>
   )
}