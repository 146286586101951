const fetchClient = async (url: string, options = {},isText?:boolean) => {
    const response = await fetch(url, {
        ...options
    });

    if (!response.ok) {
        throw new Error('Network response was not ok');
    }
    if(isText){
        return response.text();
    }else{
        return response.json();
    }

};

export default fetchClient;
