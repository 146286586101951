import { IDocumentDetails } from "../../@types/models";
import { getEnvironment360BizURL, ApiConfig, getFetchApiOptions, getEnvironment360URL } from "../configs/appConfig";
import { getHeaders } from "./dataService";
import fetchClient from "./fetchClient";

export const getProductName = async (sCB: (res: string) => void, eCB: (err: string) => void) => {
    const headers = await getHeaders();
    try {
        const response = await fetchClient(getEnvironment360BizURL() +
            ApiConfig.getProductName, {
            headers: headers,
            method: "POST",
            ...getFetchApiOptions
        });
        sCB(response as string);
    } catch (err) {
        eCB("Error : " + err);
    }
};

export const findAndGetFileInfo = async (filerecno: string, sCB: (res: IDocumentDetails) => void, eCB: (err: string) => void) => {
    const headers = await getHeaders();
    try {
        const response: IDocumentDetails = await fetchClient(getEnvironment360BizURL() +
            ApiConfig.GetArchivedFileInfo, {
            headers: headers,
            body: JSON.stringify({ recno: Number(filerecno) }),
            method: "POST",
            ...getFetchApiOptions
        });
        sCB(response);
    } catch (err) {
        eCB("Error : " + err);
    }
};


export const upload = async (data: Uint8Array, filename: string): Promise<string | undefined> => {
    const headers = await getHeaders(true);
    try {
        const response = await fetchClient(getEnvironment360URL() +
            ApiConfig.upload + "?filename=" + encodeURIComponent(filename), {
            headers: headers,
            body: data,
            method: "POST",
            ...getFetchApiOptions
        }, true);
        if (response)
            return response as string;
    } catch (err) {
        console.log("Error : " + err);
    }
};

export const uploadAndGetRecno = async (filetoken: string, filename: string, sCB: (arg0: string) => void) => {

    const headers = await getHeaders();
    try {
        const response = await fetchClient(getEnvironment360BizURL() +
            ApiConfig.SaveFileFromOffice, {
            headers: headers,
            body: JSON.stringify({ fileToken: filetoken, fileName: filename }),
            method: "POST",
            ...getFetchApiOptions
        });
        sCB(response as string);
    } catch (err) {
        console.log("Error : " + err);
    }
};

export const getFileDetailsFromFileUid = async (fileUid: string) => {

    const headers = await getHeaders();
    try {
        const response: IDocumentDetails = await fetchClient(getEnvironment360BizURL() +
            ApiConfig.GetFileInfoFromUid, {
            headers: headers,
            body: JSON.stringify({ fileuid: fileUid }),
            method: "POST",
            ...getFetchApiOptions
        });
        return response;
    } catch (err) {
        console.log("Error : " + err);
    }
};