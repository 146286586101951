import * as React from 'react';
import { Spinner, SpinnerProps, makeStyles, mergeClasses } from '@fluentui/react-components';
export interface ISpinnerWithOverlayProps  extends SpinnerProps  {
    showSpinner: boolean;
    isDarkOverlay?: boolean;
    spinnerStyle?:string;
}

const useStyles = makeStyles({
    spinnerContainer:
     {
        alignSelf: 'center'
     },
  });

const SpinnerComponent: React.FC<ISpinnerWithOverlayProps> = (props: ISpinnerWithOverlayProps) => {
    const styles = useStyles();
    const spinnerClasses = mergeClasses(styles.spinnerContainer,props.spinnerStyle);

    if(props.showSpinner){
        return (
                <div className={spinnerClasses}>
                    <Spinner  {...props} />
                </div>
        );
    }else{
        return null;
    }
};
export default SpinnerComponent;