export function isValidDomain(urlParam: string) {
    try {
      const acceptedDomains = ["360online.com", "business360online.com"];
      const url = new URL(urlParam);
      const domain = url.hostname;

      if (window.location.host.endsWith('dev.360online.com')) {
            acceptedDomains.push("dev.com");
        }

      // Check if domain matches any accepted domain patterns
      return acceptedDomains.some(acceptedDomain => {
        const pattern = new RegExp(`^(.+\\.)?${acceptedDomain.replace(/\./g, '\\.')}$`);
        return pattern.test(domain);
      });
    } catch (error) {
      return false;
    }
  }